.treeRoot {
  padding: 20px;
  height: 100%;
  list-style: none;
  ul {
    list-style: none;
  }
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e8f0fe;
}
